import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ZZZGameModes: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Inferno Reap</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_inferno.webp"
            alt="Inferno Reap"
          />
        </div>
        <div className="page-details ">
          <h1>Inferno Reap</h1>
          <h2>
            All you need to know about Inferno Reap Game Mode in Zenless Zone
            Zero.
          </h2>
          <p>
            Last updated: <strong>18/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Inferno Reap Video Guide" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="mc1HDGTGUpA" />
          </Col>
        </Row>
        <SectionHeader title="Introduction" />
        <p>
          The new Inferno Reap game mode is a twist on the old Hollow Zero:
          Withering Garden mode. Previously, reaching and fighting Nineveh
          required completing several rounds of a TV and combat mixed gamemode.
          However with the new Inferno Reap, players are now able to fight
          Nineveh immediately.
        </p>
        <p>
          To unlock this mode, players must first clear a run of Hollow Zero:
          Withering Garden - Core and then complete the Commission Quest:
          Foliage Hunt. Once unlocked, participating in the new event rewards
          players with Polychromes, Dennies, Master Copies and more.
        </p>
        <SectionHeader title="How it works" />
        <p>
          The main objective in Inferno Reap is to defeat Nineveh as quickly as
          possible so that players can gain points. Damage dealt directly
          converts to points received, so focusing on this is the number one
          priority. Collecting points is also a major focus because the rewards
          in Inferno Reap are locked behind tasks like “Deal 5,000,000 DMG to
          Nineveh in a single Inferno Reap operation” or “Deal a total of
          200,00,000 damage to Nineveh”.
        </p>
        <p>
          Nineveh is locked to level 60 in this mode which does mean that it
          deals less damage compared to an Extreme Intensity 11/11 Withering
          Garden run, but agents are still capable of dying and cannot Revive.
          In terms of moves and attacks that it has in its kit, Inferno Reap’s
          Nineveh is identical to Withering Garden’s. However, there is a new
          Rest/Break phase each time that Nineveh is defeated in this mode.
          During this period, players can attack Vines or Hornets that have not
          been defeated to increase the total time remaining to clear the fight.
        </p>
        <p>
          Before entering the Inferno Reap encounter, players are able to select
          a Resonium Kit which is the buff-system that is present in Hollow
          Zero. This is extremely important for clearing Nineveh efficiently. To
          receive a Resonium kit, players will first need to clear a run in the
          Withering Garden and select the prompt to save the kit taken in the
          run. The Resonias that are included in each kit are the exact ones
          purchased, obtained, or rewarded when participating in the Withering
          Garden, so choosing buffs wisely in this mode is also very important.
        </p>
        <SectionHeader title="Tips and Tricks for Clearing Inferno Reap" />
        <ul>
          <li>
            Clear all Hornets or Vines when possible, but do not waste
            abilities/excessive time to defeat every single one.
          </li>
          <li>
            Elemental, Dual and Critical Resonia are great choices for this mode
            since damage is the number one priority. The stun damage from Dual
            and the potential to execute hornets instantly when taking enough
            Critical Resonias are incredibly valuable and are absolute must
            haves.
          </li>
          <li>
            Shield Based/Defensive Resonia are less effective here since
            receiving a peak score is more important.
          </li>
          <li>
            Shuttlecock (which provides Vital View aka “Slow Motion” to your
            dodges) is still great here, but Dodging when the enemy is
            un-targetable will extend this period and sometimes waste valuable
            time. It is still extremely important for Freezing or Pausing
            Nineveh however. During the Frozen periods, agents can Stun or
            unload their high damage skills into Nineveh which can allow players
            to avoid attack phases entirely.
          </li>
          <li>
            Stunning Nineveh is more realistic here as opposed to Withering
            Garden’s 11/11 difficulty mode, so using Daze based characters like
            Anby, Qingyi, or Koleda to increase damage dealt alongside
            Crit-based characters is ideal.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZGameModes;

export const Head: React.FC = () => (
  <Seo
    title="Game Modes | Zenless Zone Zero | Prydwen Institute"
    description="All available game modes in Zenless Zone Zero showcased."
    game="zzz"
  />
);
